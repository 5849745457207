<template>
  <div class="app-container h">
    <!-- 左侧树 -->
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">自定义区域</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toSearch" />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="flex v">
      <div class="head-container">
        <el-input v-model="query.disEntName" clearable placeholder="根据经销商名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toSearch" />
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toSearch" />

        <!-- <quick-select v-model="query.provinceCode" :label.sync="query.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 150px;" class="filter-item" clearable @change="toSearch" />
        <quick-select v-if="query.provinceCode" v-model="query.cityCodes" multiple :label.sync="query.cityName" :url="`api/regionalDict/parent/${query.provinceCode}`" value-field="code" placeholder="选择城市" filterable style="width: 150px;" class="filter-item" @change="toSearch" />
        <tree-picker v-model="query.areaId" url="api/area" style="width: 150px;" placeholder="经销商区域" clearable flat class="filter-item" @change="toSearch" />
        <el-select v-model="query.disabled" placeholder="根据当前限购状态搜索" style="width:150px" class="filter-item" clearable @change="toQuery">
          <el-option label="已限制" value="true">已限制</el-option>
          <el-option label="未限制" value="false">未限制</el-option>
        </el-select>-->
        <el-select v-model="query.isTotal" style="width:90px" class="filter-item" @change="toSearch">
          <el-option label="汇总" :value="true">汇总</el-option>
          <el-option label="明细" :value="false">明细</el-option>
        </el-select>

        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toSearch">搜索</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toSearch">刷新</el-button>
      </div>
      <el-card class="box-card" shadow="never" body-style="padding: 0 10px;">
        <div class="h sb fs-small lh-150" style="color:#606266">
          <div class="padding-10 ta-c">
            <div>累计零售额合计</div>
            <div class="bold">{{$price(performanceTotal.salePrice)}}</div>
            <div class="gap-1x">累计采购额合计</div>
            <div class="bold">{{$price(performanceTotal.purchasePrice)}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>总部发货数量合计</div>
            <div class="bold">{{$quantity(performanceTotal.sendCount)}}</div>
            <div class="gap-1x">总部发货金额</div>
            <div class="bold">{{$price(performanceTotal.sendAmount)}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>采购入库数量</div>
            <div class="bold">{{$quantity(performanceTotal.inCount)}}</div>
            <div class="gap-1x">采购入库金额</div>
            <div class="bold">{{$price(performanceTotal.inAmount)}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>已发未入数量</div>
            <div class="bold">{{$quantity(performanceTotal.sendCount - performanceTotal.inCount)}}</div>
            <div class="gap-1x">已发未入金额</div>
            <div class="bold">{{$price(performanceTotal.sendAmount - performanceTotal.inAmount)}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>累计送货额合计</div>
            <div class="bold">{{$price(performanceTotal.sendPrice)}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>零售转单率合计</div>
            <div class="bold">{{(performanceTotal.purchasePrice && performanceTotal.salePrice)?((performanceTotal.purchasePrice /performanceTotal.salePrice )*100).toFixed(2)+"%":"--"}}</div>
          </div>

          <div class="padding-10 ta-c">
            <div>到货入库率合计</div>
            <div class="bold">{{(performanceTotal.inCount && performanceTotal.sendCount)?((performanceTotal.inCount /performanceTotal.sendCount )*100).toFixed(2)+"%":"--"}}</div>
          </div>
          <div class="padding-10 ta-c">
            <div>送货及时率合计</div>
            <div class="bold">{{(performanceTotal.sendPrice && performanceTotal.salePrice)?((performanceTotal.sendPrice /performanceTotal.salePrice )*100).toFixed(2)+"%":"--"}}</div>
          </div>
        </div>
      </el-card>
      <el-table v-loading="loading" :data="data" :default-sort="{prop: 'dateId', order: 'descending'}" @sort-change="handleSort" @selection-change="handleSelectionChange" size="small" height="200">
        <el-table-column type="selection" width="40" fixed />
        <el-table-column type="index" fixed />
        <el-table-column prop="erpId" label="经销商编码" width="120" :show-overflow-tooltip="true" sortable="custom" fixed />
        <el-table-column prop="disEntName" label="经销商名称" width="240" :show-overflow-tooltip="true" sortable="custom" fixed />
        <!-- <el-table-column prop="entName" label="厂家" :show-overflow-tooltip="true" /> -->
        <el-table-column prop="provinceName" label="省份" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{scope.row.provinceName || "-"}}</template>
        </el-table-column>
        <el-table-column prop="cityName" label="城市" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{scope.row.cityName || "-"}}</template>
        </el-table-column>
        <el-table-column prop="areaName" label="区域" :show-overflow-tooltip="true" align="center" sortable="custom">
          <template slot-scope="scope">{{scope.row.areaName || "-"}}</template>
        </el-table-column>
        <el-table-column prop="day" label="日期" width="120px" :formatter="v=>{return v.day?new Date(v.day).format('yyyy/MM/dd'):''}" sortable="custom" v-if="!query.isTotal" />
        <el-table-column prop="salePrice" label="累计零售额" width="110" :formatter="$price" align="center" sortable="custom" />
        <el-table-column prop="purchasePrice" label="累计采购额" width="110" :formatter="$price" align="center" sortable="custom" />
        <el-table-column label="零售转单率" width="110" align="center" sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.purchasePrice &&scope.row.salePrice">{{((scope.row.purchasePrice/scope.row.salePrice)*100).toFixed(0)}}%</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="sendCount" label="总部发货数量" align="center" width="120" sortable="custom">
          <template slot-scope="scope">{{$quantity(scope.row.sendCount)}}</template>
        </el-table-column>
        <el-table-column prop="sendAmount" label="总部发货金额" width="120" :formatter="$price" align="center" />
        <el-table-column prop="inCount" label="采购入库数量" align="center" width="120" sortable="custom">
          <template slot-scope="scope">{{$quantity(scope.row.inCount)}}</template>
        </el-table-column>
        <el-table-column prop="inAmount" label="采购入库金额" width="120" :formatter="$price" align="center" />
        <el-table-column label="已发未入数量" width="120" align="center">
          <template slot-scope="scope">{{$quantity(scope.row.sendCount - scope.row.inCount)}}</template>
        </el-table-column>
        <el-table-column label="已发未入金额" width="120" align="center">
          <template slot-scope="scope">{{$price(scope.row.sendAmount - scope.row.inAmount)}}</template>
        </el-table-column>
        <el-table-column label="到货入库率" width="110" align="center" sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.inCount &&scope.row.sendCount">{{((scope.row.inCount/scope.row.sendCount)*100).toFixed(0)}}%</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="sendPrice" label="累计送货额" width="110" :formatter="$price" align="center" sortable="custom" />
        <el-table-column label="送货及时率" width="110" align="center" sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.sendPrice &&scope.row.salePrice">{{((scope.row.sendPrice/scope.row.salePrice)*100).toFixed(0)}}%</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <!--分页组件-->
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import { mapGetters } from "vuex";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";
import DeptSelect from "@/views/statistics/purchaseAll/deptSelect";

export default {
  mixins: [initData],
  components: { DeptSelect },
  data() {
    return {
      delLoading: false,
      downloadLoading: false,
      loading: false,
      sort: "erpId",
      performanceTotal: {},
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        isTotal: true,
        dateRange: [],
        disEntName: null,
        areaName: null,
        disabled: null,
        provinceCode: null,
        provinceName: null,
        cityCodes: null,
        cityName: null,
        areaId: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
      this.loadTotal();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "@host:analysis;api/performanceData/day";
      if (this.query.dateRange && this.query.dateRange.length === 2) {
        this.query.beg = new Date(this.query.dateRange[0]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
        this.query.end = new Date(this.query.dateRange[1]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        delete this.query.beg;
        delete this.query.end;
      }

      this.params = Object.assign(
        { page: this.page, size: this.size, sort: this.sort },
        this.query
      );

      return true;
    },
    afterLoad(ds) {
      ds.forEach((d) => (d._enabled = !d.disabled));
    },
    changeStatus(val, row) {
      let data = { disEntId: row.disEntId, disabled: !val };
      request({
        url: "api/performanceState/change",
        method: "put",
        data,
      })
        .then((res) => {
          this.$notify({
            title: "修改状态成功",
            type: "success",
            duration: 2500,
          });
        })
        .finally((_) => {
          this.init();
        });
    },
    handleSort(sort) {
      if (sort.order == null) {
        this.sort = "";
      } else if (sort.column.label == "零售转单率") {
        this.sort =
          "saleRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "到货入库率") {
        this.sort =
          "inStoreRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "送货及时率") {
        this.sort =
          "sendRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "当前限购状态") {
        this.sort = "disabled," + (sort.order === "ascending" ? "asc" : "desc");
      } else {
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      }
      this.toQuery();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      let params = Object.assign(
        { page: 0, size: this.total, sort: this.sort },
        this.query
      );
      download("@host:analysis;api/performanceData/day/export", params)
        .then((result) => {
          downloadFile(result, "考核分析", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    loadTotal() {
      if (this.query.dateRange && this.query.dateRange.length === 2) {
        this.query.beg = new Date(this.query.dateRange[0]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
        this.query.end = new Date(this.query.dateRange[1]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        delete this.query.beg;
        delete this.query.end;
      }

      request({
        url: "@host:analysis;api/performanceData/day/total",
        method: "get",
        params: this.query,
      }).then((res) => {
        this.performanceTotal = res;
      });
    },
    handleSelectionChange(val) {
      if (val.length > 0) {
        this.performanceTotal = {
          salePrice: null,
          purchasePrice: null,
          sendCount: null,
          inCount: null,
          sendPrice: null,
          saleRatio: null,
          inStoreRatio: null,
          sendRatio: null,
        };
        (val || []).forEach((o) => {
          this.performanceTotal.salePrice += o.salePrice || 0;
          this.performanceTotal.purchasePrice += o.purchasePrice || 0;
          this.performanceTotal.sendCount += o.sendCount || 0;
          this.performanceTotal.inCount += o.inCount || 0;
          this.performanceTotal.sendPrice += o.sendPrice || 0;
          this.performanceTotal.saleRatio += o.saleRatio || 0;
          this.performanceTotal.inStoreRatio += o.inStoreRatio || 0;
          this.performanceTotal.sendRatio += o.sendRatio || 0;
        });
      } else {
        this.loadTotal();
      }
    },
    toSearch() {
      this.toQuery();
      this.loadTotal();
    },
  },
};
</script>